<template>
  <ui-body-wrap>
    <common-headers-dashboard />
    <main
      :class="[isAuthenticated ? 'md:max-h-[calc(100vh-5rem)] max-h-[calc(100vh-3.5rem)]' : 'max-h-screen']"
      class="relative flex flex-1 px-2.5 lg:px-5 max-h-96 overflow-auto"
    >
      <slot />
    </main>
  </ui-body-wrap>
</template>

<script setup lang="ts">
  const { isAuthenticated } = useSanctumAuth();
</script>
