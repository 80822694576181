<template>
  <ui-button
    :variant="showNav ? 'secondary-outline' : 'link'"
    :icon-left="showNav ? 'close' : 'menu'"
    size="sm-icon"
    @click="onClickBurger"
  />

  <teleport to="body">
    <div
      v-if="showNav"
      class="fixed top-0 pt-16 md:pt-24 inset-0 overflow-y-auto capitalize z-40 flex bg-body"
    >
      <div class="absolute inset-0 bg-gray/50 backdrop-blur-sm" />
      <div class="flex flex-col flex-1 rounded-lg p-4 m-2.5">
        <div class="flex justify-end pb-5">
          <common-headers-set-them />
        </div>
        <common-headers-navigation
          :items="items"
          link-class="w-full"
          class="relative z-[1] flex-col items-stretch max-w-96 mx-auto"
          @link-click="toggleNav(false)"
        />
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
  type Props = {
    items: NavLink[];
  };

  defineProps<Props>();

  const [showNav, toggleNav] = useToggle();
  const isLocked = useScrollLock(window);

  const onClickBurger = () => {
    toggleNav();
    isLocked.value = showNav.value;
  };
</script>
