<template>
  <header
    v-if="user"
    class="mx-2.5 lg:mx-5 mb-2.5 lg:mb-7 z-50 relative md:h-[5rem] h-14"
  >
    <ui-container
      spacing="none"
      no-gutters-x
      class="flex md:gap-3 gap-2"
    >
      <ui-logo />

      <div class="bg-card rounded-full py-2 px-2.5 lg:px-3.5 xl:gap-10 flex-1 gap-2 flex items-center">
        <common-headers-navigation
          :items="appNav"
          class="capitalize flex-1 items-center"
        >
          <template #icon-left="{ itemKey }">
            <ui-avatar
              v-if="itemKey === 'profile'"
              :src="user.avatar_url"
              size="xs"
              border
              :user-name="fullName"
              class="lg:mr-1.5"
            />
          </template>
        </common-headers-navigation>
        <template v-if="!isLg">
          <common-headers-set-them class="ml-auto" />
          <common-headers-navigation
            :items="secondaryNav"
            class="capitalize items-center xl:gap-10"
          />
        </template>

        <common-notifications-button
          v-if="isLg"
          is-mobile
        />
        <common-notifications-dropdown-button v-else />
        <common-headers-mobile-sub-nav
          v-if="isLg"
          :items="secondaryNav"
        />
      </div>
    </ui-container>
  </header>
</template>
<script setup lang="ts">
  const isLg = useIsLessThan('lg');
  const fullName = useCurrentUserName();
  const user = useSanctumUser<User>();

  const settingItem = computed(() => ({
    itemKey: 'settings',
    linkProps: {
      text: 'Settings',
      to: {
        name: isLg.value ? 'dashboard-settings' : 'dashboard-settings-general'
      },
      size: 'lg',
      iconVariant: 'secondary',
      variant: isLg.value ? 'secondary' : 'ghost',
      iconLeft: isLg.value ? 'tune' : '',
      class: 'w-full lg:w-auto'
    }
  }));

  const secondaryNav = computed<NavLink[]>(() => {
    const links: NavLink[] = [
      {
        itemKey: 'upgradeAccount',
        linkProps: {
          text: 'Upgrade profile',
          to: {
            name: 'dashboard-subscriptions'
          },
          size: 'lg',
          iconVariant: 'secondary',
          rounded: 'full',
          variant: 'secondary',
          iconLeft: 'star',
          class: 'lg:min-w-40 min-w-full'
        }
      },
      {
        itemKey: 'bayCoins',
        linkProps: {
          text: 'Buy coins',
          to: {
            name: 'dashboard-coins'
          },
          iconVariant: 'secondary',
          variant: 'secondary',
          size: 'lg',
          rounded: 'full',
          iconLeftType: 'custom',
          iconLeft: 'database',
          class: 'lg:min-w-40  min-w-full'
        }
      }
    ];

    if (isLg.value) {
      links.unshift(settingItem.value);
    }

    return links;
  });

  const appNav = computed<NavLink[]>(() => {
    const items: NavLink[] = [
      {
        itemKey: 'profile',
        liClass: 'order-2 lg:-order-1',
        linkProps: {
          text: isLg.value ? '' : 'My Profile',
          to: { name: 'dashboard-profile' },
          class: 'lg:py-2',
          rounded: 'full',
          variant: 'ghost',
          hideSpace: isLg.value,
          size: 'lg'
        }
      },
      {
        itemKey: 'search',
        linkProps: {
          text: isLg.value ? '' : 'Search',
          iconLeft: isLg.value ? 'search' : '',
          variant: 'ghost',
          size: isLg.value ? 'sm-icon' : 'lg',
          to: { name: 'dashboard-rooms' }
        }
      }
    ];
    if (isLg.value) {
      items.push({
        liClass: 'order-1 lg:hidden',
        itemKey: 'chat',
        linkProps: {
          to: {
            name: 'dashboard-my-rooms'
          },
          size: 'sm-icon',
          iconLeft: 'chat',
          variant: 'ghost',
          rounded: 'sm'
        }
      });
    } else {
      items.push(settingItem.value);
    }

    return items;
  });
</script>
