<template>
  <v-dropdown
    v-model:shown="shown"
    theme="custom"
    :triggers="['click']"
    :distance="44"
    :skidding="14"
    :auto-hide="false"
    placement="bottom-end"
  >
    <template #default="{ shown }">
      <common-notifications-button :active="shown" />
    </template>
    <template #popper="{ hide }">
      <common-notifications-list
        list-wrap-classes="w-[445px] max-h-80"
        @close="hide"
      />
    </template>
  </v-dropdown>
</template>

<script setup lang="ts">
  const [shown, toggleShown] = useToggle(false);

  useEventListener(document, 'click', event => {
    if (shown.value) {
      const target = event.target as HTMLElement;
      if (
        target.classList.contains('v-popper') ||
        target.classList.contains('v-popper__popper') ||
        target.closest('.v-popper') ||
        target.closest('.v-popper__popper') ||
        target.closest('.vfm--inset') // Modal
      ) {
        return;
      }
      toggleShown(false);
    }
  });
</script>
