<template>
  <ui-button-with-notify
    variant="primary-light"
    icon-left="notifications_none"
    class="text-2xl"
    rounded="full"
    :active="active"
    v-bind="btnAttrs"
    :has-notify="isNotifications"
    :small="isMobile"
  />
</template>

<script setup lang="ts">
  type Props = {
    isMobile?: boolean;
    active?: boolean;
  };
  const props = defineProps<Props>();
  const { user } = useAuthUser();
  const isNotifications = computed(() => user.value?.unread_notifications_exists);

  const btnAttrs = computed(() => {
    if (props.isMobile) {
      return {
        size: 'sm-icon',
        to: { name: 'dashboard-notifications' }
      };
    }
    return {
      size: 'lg-icon'
    };
  });
</script>
